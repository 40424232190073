import type { ButtonData } from '@/components/MenuNavigation/types/interfaces'
import {category} from '@/components/MenuNavigation/data/category'
import {buttonCode} from '@/components/MenuNavigation/data/buttonCode'

import { markRaw } from 'vue';
import {
    PhHouse,
    PhChats,
    PhCalendar,
    PhVideoCamera,
    PhCheckSquare,
    PhGitBranch,
    PhCube,
    PhAddressBook,
    PhGear,
    PhFolder,
    PhKey,
    PhList,
    PhNote,
    PhEnvelopeSimple
} from "@phosphor-icons/vue";

export const buttons: ButtonData[] = [
    {
        title: '',
        icon: markRaw(PhList),
        url: '/',
        category: category.options,
        isShown: true,
        isActive: false,
        code: buttonCode.extend,
        hasNotification: false
    },
    {
        title: 'Accueil',
        icon: markRaw(PhHouse),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: true,
        code: buttonCode.espaces,
        hasNotification: false
    },
    {
        title: 'Contacts',
        icon: markRaw(PhAddressBook),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.contacts,
        hasNotification: false
    },
    {
        title: 'Documents',
        icon: markRaw(PhFolder),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.documents,
        hasNotification: false
    },
    {
        title: 'Dialogues',
        icon: markRaw(PhChats),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.dialogues,
        hasNotification: false
    },
    {
        title: 'Visioconférences',
        icon: markRaw(PhVideoCamera),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.visioconference,
        hasNotification: false
    },
    {
        title: 'Agenda',
        icon: markRaw(PhCalendar),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.agenda,
        hasNotification: false
    },
    {
        title: 'Actualités',
        icon: markRaw(PhNote),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.actualites,
        hasNotification: false
    },
    {
        title: 'Mails',
        icon: markRaw(PhEnvelopeSimple),
        url: '/',
        category: category.principal,
        isShown: true,
        isActive: false,
        code: buttonCode.mail,
        hasNotification: false
    },
    {
        title: 'Projets',
        icon: markRaw(PhCube),
        url: '/',
        category: category.project,
        isShown: true,
        isActive: false,
        code: buttonCode.projets,
        hasNotification: false
    },
    {
        title: 'Workflows',
        icon: markRaw(PhGitBranch),
        url: '/',
        category: category.project,
        isShown: true,
        isActive: false,
        code: buttonCode.workflows,
        hasNotification: false
    },
    {
        title: 'Tâches',
        icon: markRaw(PhCheckSquare),
        url: '/',
        category: category.project,
        isShown: true,
        isActive: false,
        code: buttonCode.tache,
        hasNotification: false
    },
    {
        title: 'Resana Secure',
        icon: markRaw(PhKey),
        url: '/',
        category: category.parameter,
        isShown: false,
        isActive: false,
        code: buttonCode.resanaSecure,
        hasNotification: false
    },
    {
        title: 'Drive sécurisé',
        icon: markRaw(PhKey),
        url: '/',
        category: category.project,
        isShown: false,
        isActive: false,
        code: buttonCode.resanaSecureDrive,
        hasNotification: false
    },
    {
        title: 'Administration',
        icon: markRaw(PhGear),
        url: '/',
        category: category.parameter,
        isShown: false,
        isActive: false,
        code: buttonCode.administration,
        hasNotification: false
    },
];