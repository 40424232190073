//@ts-nocheck 
import { defineCustomElement } from 'vue';

import App from './App.ce.vue';

//On verifie que le web component n'est pas deja declaré pour eviter une erreur
//TODO: refacto pour centraliser ça 

if(!customElements.get('its-navigation-menu')){
    customElements.define('its-navigation-menu', defineCustomElement(App));
}