import {MenuState} from "@/types/namespace/MenuState.namespace";
import {ref} from "vue";

const menuState:MenuState.RefMenuState = ref(MenuState.Types.Extended);

export default function useMenuState(){
    const isExtended = () => {
        return menuState.value === MenuState.Types.Extended;
    }

    const isCollapsed = () => {
        return menuState.value === MenuState.Types.Collapsed;
    }

    const toggleState = () => {
        menuState.value = isCollapsed() ? MenuState.Types.Extended : MenuState.Types.Collapsed;
    }

    return {menuState, isExtended, isCollapsed, toggleState};
}