import axios from 'axios';
import { ref } from 'vue';
import type { EnvironmentVariables } from '@/types/interfaces/EnvironmentVariables';
import {standaloneConfigType} from "@/types/menuNavigation";

let environmentVariables:EnvironmentVariables = {
    basePath: ref(''),
    hostname: ref(''),
    presetGraphique: ref('default'),
    presetGraphiqueImg: ref(''),
    platformLogo: ref(''),
    newsIntranetIsEnabled: ref(false),
    homeMailButtonIsEnabled: ref(false),
    webmailUrl: ref('')
};

export function useEnvVariables() {
    const getEnvironmentVariables = async (standaloneConfig?: standaloneConfigType) => {
        if (standaloneConfig) {
            environmentVariables.basePath.value = standaloneConfig.basePath ?? '';
            environmentVariables.hostname.value = standaloneConfig.hostname ?? '';
            environmentVariables.presetGraphique.value = standaloneConfig.kitGraphique.preset ?? '';
            environmentVariables.presetGraphiqueImg.value = standaloneConfig.kitGraphique.background_image ?? '';
            environmentVariables.platformLogo.value = standaloneConfig.logos.homepage ?? '';
            environmentVariables.newsIntranetIsEnabled.value = standaloneConfig.newsIntranetIsEnabled ?? '';
            environmentVariables.homeMailButtonIsEnabled.value = standaloneConfig.homeMailButtonIsEnabled ?? '';
            environmentVariables.webmailUrl.value = standaloneConfig.webmailUrl ?? '';
        } else {
            const response = await axios({
                method: 'get',
                url: `${detectLocalHost()}/public/aide/getEnvironmentVariables`
            });

            environmentVariables.basePath.value = response.data.basePath ?? '';
            environmentVariables.hostname.value = response.data.hostname ?? '';
            environmentVariables.presetGraphique.value = response.data.kitGraphique.preset ?? '';
            environmentVariables.presetGraphiqueImg.value = response.data.kitGraphique.background_image ?? '';
            environmentVariables.platformLogo.value = response.data.logos.homepage ?? '';
            environmentVariables.newsIntranetIsEnabled.value = response.data.newsIntranetIsEnabled ?? '';
            environmentVariables.homeMailButtonIsEnabled.value = response.data.homeMailButtonIsEnabled ?? '';
            environmentVariables.webmailUrl.value = response.data.webmailUrl ?? '';
        }
    };

    const detectLocalHost = () => {
        return window.location.host === '127.0.0.1' ? '/plateforme' : '';
    };

    return {
        getEnvironmentVariables,
        detectLocalHost,
        environmentVariables
    };
}