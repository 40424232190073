import type {ButtonData} from './interfaces'
import {ref, type Ref} from 'vue'

export default class MenuNavigationModel{
    title: Ref<string>;
    icon: Ref<any>;
    url: Ref<string>;
    category: Ref<string>;
    isShown: Ref<boolean>;
    isActive: Ref<boolean>;
    code: Ref<string>;
    hasNotification: Ref<boolean>;
    constructor(data: ButtonData) {
        this.title = ref(data.title)
        this.icon = ref(data.icon)
        this.url = ref(data.url)
        this.category = ref(data.category)
        this.isShown = ref(data.isShown)
        this.isActive = ref(data.isActive)
        this.code = ref(data.code)
        this.hasNotification = ref(data.hasNotification)
    }
}