import { ref, type Ref } from "vue";
import type { ThemePresetCodeUnion } from '@/types/menuNavigation';
import ThemePresetCode from '@/types/ThemePresetCode';

let selectedTheme:Ref<ThemePresetCodeUnion | null> =  ref(null);

export function useTheme(){
    const setSelectedTheme = (themePreset:ThemePresetCodeUnion):ThemePresetCodeUnion => {
        return  ThemePresetCode.includes(themePreset) ? themePreset : 'default' ;
    }

    return {setSelectedTheme, selectedTheme}
}