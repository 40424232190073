import { type ComputedRef, computed } from 'vue';
import type MenuNavigationModel from '@/components/MenuNavigation/types/model';
import {category} from '@/components/MenuNavigation/data/category'

export function useButtonFilter(buttons: MenuNavigationModel[]) {    
    function filter(category: string) : ComputedRef<MenuNavigationModel[]>{
        return computed(() => {
            return buttons.filter(button => button.category.value === category && button.isShown.value);
        });
    }

    const optionsButtons = filter(category.options);
    
    const principalButtons = filter(category.principal);
    
    const projetButtons = filter(category.project);
    
    const parameterButtons = filter(category.parameter);

    return {principalButtons, projetButtons, parameterButtons, optionsButtons};
}