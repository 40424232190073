import { ref } from 'vue';
import { buttons } from '@/components/MenuNavigation/data/buttons';
import { buttonCode } from '@/components/MenuNavigation/data/buttonCode';
import { category } from '@/components/MenuNavigation/data/category';

import MenuNavigationModel from '@/components/MenuNavigation/types/model';

import type { MenuNavigation } from '@/components/MenuNavigation/types/interfaces';
import type {setButtonNotificationType} from "@/types/menuNavigation";

const menuNavigation:MenuNavigation = {
    activeButton: ref(buttonCode.espaces),
    menuButtons: buttons.map((data) => new MenuNavigationModel(data)),
}

export function useNavigationData(isAdmin: boolean){
    const findButtonByCode = (code: string) => {
        return menuNavigation.menuButtons.findIndex(button => button.code.value === code)
    }

    if(isAdmin){
        let index = findButtonByCode(buttonCode.administration)
        menuNavigation.menuButtons[index].isShown.value = isAdmin
    }

    const setButtonActive = (code: string): void => {
        if(menuNavigation.menuButtons[findButtonByCode(code)].category.value !== category.options){
            menuNavigation.activeButton.value = code;
        }
    }

    const setButtonDisplay = (code: string, state: boolean) => {
        let index = findButtonByCode(code);
        menuNavigation.menuButtons[index].isShown.value = state;
    }

    const isButtonActive = ((code:string) => {
        return menuNavigation.activeButton.value === code;
    });

    const setButtonNotification: setButtonNotificationType = (code, state) => {
        let index = findButtonByCode(code)
        menuNavigation.menuButtons[index].hasNotification.value = state
    }

    return {menuNavigation, isButtonActive, setButtonActive, setButtonNotification, setButtonDisplay, findButtonByCode}
}