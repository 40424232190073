export const buttonCode = {
    extend: 'extend',
    espaces: 'espaces',
    contacts: 'contacts',
    documents: 'documents',
    dialogues: 'dialogues',
    visioconference: 'visioconference',
    agenda: 'agenda',
    projets: 'projets',
    actualites: 'actualites',
    mail: 'mail',
    workflows: 'workflows',
    tache: 'tache',
    administration: 'administration',
    resanaSecure: 'resanaSecure',
    resanaSecureDrive: 'resanaSecureDrive',
}