import {Ref} from "vue";

export namespace MenuState {
    export enum Types {
        Collapsed = 'collapsed',
        Extended = 'extended'
    }

    export type TypesUnion = `${Types}`
    export type RefMenuState = Ref<TypesUnion>
}