<template>
  <div :class="['slider-menu', selectedTheme, menuState]">
    <MenuNavigation
      v-if="environmentVariables.basePath.value != ''"
    />
  </div>
</template>

<script setup lang="ts">
import {onMounted, provide} from 'vue';
import MenuNavigation from '@/components/MenuNavigation/MenuNavigation.vue';
import { useEnvVariables } from '@/composables/useEnvVariables';
import { useTheme } from '@/composables/useTheme';
import {useNavigationData} from "@/composables/useNavigationData";
import {AppProps, standaloneConfigType} from '@/types/menuNavigation';
import useMenuState from "@/composables/useMenuState";

const props = withDefaults(defineProps<AppProps>(), {
    isAdmin: false
});

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits<{
    navigation: [{code: string}]
}>();

const { getEnvironmentVariables, environmentVariables } = useEnvVariables();
const { setSelectedTheme, selectedTheme } = useTheme();
const { menuState, toggleState } = useMenuState();
const { setButtonNotification, setButtonDisplay } = useNavigationData(props.isAdmin);

onMounted(async() => {
  if (!props.isStandalone) {
    await getEnvironmentVariables();
    selectedTheme.value = setSelectedTheme(environmentVariables.presetGraphique.value);

    if(!environmentVariables.newsIntranetIsEnabled.value) {
      setButtonDisplay('actualites', false);
    }
    if(!environmentVariables.homeMailButtonIsEnabled.value) {
      setButtonDisplay('mail', false);
    }
  }
});

const emitNavigation = (code: string) => {
  if(code === 'extend'){
    toggleState();
    emit('navigation', {code: menuState.value});
    return;
  }

  emit('navigation', {code});
  setButtonNotification(code, false);
};

provide('emitNavigation', emitNavigation);
provide('appProps', props);

const { setButtonActive } = useNavigationData(props.isAdmin);

const setStandaloneConfig = async (value: standaloneConfigType) => {
  await getEnvironmentVariables(value);
  selectedTheme.value = setSelectedTheme(environmentVariables.presetGraphique.value);
  const rootEl = <HTMLElement>document.querySelector('its-navigation-menu');

  const backgroundImg = `${window.location.protocol}//${environmentVariables.hostname.value}/${environmentVariables.presetGraphiqueImg.value}`;
  rootEl.style.backgroundImage = `url('${backgroundImg}')`;

  if(!environmentVariables.newsIntranetIsEnabled.value) {
    setButtonDisplay('actualites', false);
  }
  if(!environmentVariables.homeMailButtonIsEnabled.value) {
    setButtonDisplay('mail', false);
  }
}

defineExpose({setButtonNotification, setButtonDisplay, setButtonActive, toggleState, setStandaloneConfig})
</script>

<style lang="scss">
    @import '@/styles/main.scss';
    @import './App.scss';
    @import '@/components/MenuNavigation/MenuNavigation.scss';
    @import '@/components/MenuNavigationCategory/MenuNavigationCategory.scss';
    @import '@/components/MenuButton/MenuButton.scss';
</style>
