import {inject} from 'vue'
import type MenuNavigationModel from '@/components/MenuNavigation/types/model';

type EmitNavigation = {
    (code: string): void;
}

export function useNavigation(){
    const emitNavigation = inject<EmitNavigation>('emitNavigation', () => {console.warn('inject has no value')})
    const startNavigation = (button: MenuNavigationModel): void => {
        emitNavigation(button.code.value);
    }

    const redirectToExternMail =(url: string): void => {
        window.open(url, '_blank');
    }

    return { startNavigation , redirectToExternMail }
}