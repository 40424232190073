<template>
    <button :class="['menu-item', {'has-notification': notification}]" :title="isExtended() ? '' : title">
        <div class="menu-item__icon">
            <span class="menu-item__pastille"></span>
            <component :is="props.iconName" :weight="props.iconWeight"></component>
        </div>
        <span v-if="isExtended()" class="menu-item__title">{{title}}</span>
    </button>
</template>

<script setup lang="ts">
    import { type Component  } from 'vue';
    import useMenuState from "@/composables/useMenuState";
    const { isExtended } = useMenuState();

    const props = defineProps<{
        iconName: Component
        iconWeight: string
        notification: boolean
        title: string
    }>();
</script>
