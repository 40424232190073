export default [
    'black',
    'french_blue',
    'dark_blue',
    'light_blue',
    'dark_gray',
    'light_gray',
    'dark_green',
    'light_green',
    'dark_orange',
    'light_orange',
    'dark_pink',
    'light_pink',
    'dark_purple',
    'light_purple',
    'dark_red',
    'light_red',
    'dark_yellow',
    'light_yellow',
    'default'
] as const;

